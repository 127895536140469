import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoComponent } from './auto/auto.component';
import { TimeComponent } from './time/time.component';

const routes: Routes = [
  {
    path: "time",
    component: TimeComponent,
  },
  {
    path: ":type",
    component: AutoComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
